import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import { ChallengeStatus } from '@/models/Challenge';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

export function calculateTimeRemaining(challenge: any): { message: string; status: string | null } {
    const startDate = dayjs.utc(challenge.start_date).tz('America/New_York');
    const endDate = dayjs.utc(challenge.end_date).tz('America/New_York');
    const judgeEndDate = dayjs.utc(challenge.judge_end).tz('America/New_York');
    const now = dayjs().tz('America/New_York');

    if (now.isBefore(startDate)) {
        const diff = dayjs.duration(startDate.diff(now));
        const days = Math.floor(diff.asDays());
        const hours = Math.floor(diff.asHours() % 24);
        const minutes = Math.floor(diff.asMinutes() % 60);
        const seconds = Math.floor(diff.asSeconds() % 60);

        return {
            message: `Challenge starts in ${days}d ${hours}h ${minutes}m ${seconds}s`,
            status: ChallengeStatus.PUBLISHED,
        };
    }

    if (now.isAfter(judgeEndDate)) {
        if (challenge.status === 'completed') {
            return {
                message: ' Challenge Complete',
                status: ChallengeStatus.COMPLETED,
            };
        }
        return {
            message: ' Challenge over. Results will be announced soon.',
            status: ChallengeStatus.COMPLETED,
        };
    } else if (now.isAfter(endDate)) {
        const diff = dayjs.duration(judgeEndDate.diff(now));
        const days = Math.floor(diff.asDays());
        const hours = Math.floor(diff.asHours() % 24);
        const minutes = Math.floor(diff.asMinutes() % 60);
        const seconds = Math.floor(diff.asSeconds() % 60);

        return {
            message: `Judging ends in ${days}d ${hours}h ${minutes}m ${seconds}s`,
            status: ChallengeStatus.JUDGING,
        };
    } else if (now.isAfter(startDate) && now.isBefore(endDate)) {
        const diff = dayjs.duration(endDate.diff(now));
        const days = Math.floor(diff.asDays());
        const hours = Math.floor(diff.asHours() % 24);
        const minutes = Math.floor(diff.asMinutes() % 60);
        const seconds = Math.floor(diff.asSeconds() % 60);

        return {
            message: `Judging begins in ${days}d ${hours}h ${minutes}m ${seconds}s`,
            status: ChallengeStatus.ACTIVE,
        };
    } else {
        return {
            message: ' Challenge not started',
            status: ChallengeStatus.FAILED,
        };
    }
}
