<template>
  <canvas ref="qrCodeRef"></canvas>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import QRCode from 'qrcode';

// Define props
const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  width: {
    type: Number,
    default: 300,
  },
});

// References
const qrCodeRef = ref(null);

// Function to generate QR code
const generateQRCode = async (text) => {
  if (!qrCodeRef.value) return;

  // Clear previous QR code
  qrCodeRef.value.innerHTML = '';

  try {
    // Generate QR code as Canvas and append to the div
    await QRCode.toCanvas(qrCodeRef.value, text, {
      width: props.width,
      margin: 2,
      errorCorrectionLevel: 'H',
    });
  } catch (err) {
    console.error('Failed to generate QR code:', err);
  }
};

// Initial generation
onMounted(() => {
  generateQRCode(props.url);
});

// Watch for changes in the url prop
watch(
  () => props.url,
  (newUrl) => {
    generateQRCode(newUrl);
  }
);
watch(
  () => props.width,
  () => {
    generateQRCode(props.url);
  }
);
</script>

<style scoped>
/* Optional: Center the QR code */
div {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
