import api from '@/services/api';
import { cacheWithTTL } from '@/utils/cacheWithTTTL';


export interface UserProfileData {
    id?: string;
    username?: string;
    email?: string;
    first_name?: string;
    last_name?: string;
    preferred_name?: string;
    profile_photo?: string;
    cover_photo?: string;
    phone_number?: string;
    provider?: string;
    additional_info?: string;
    bio?: string;
    show_tour?: boolean;
}

export class UserProfile {
    public id: string;
    public username: string;
    public email: string;
    public first_name: string;
    public last_name: string;
    public preferred_name: string;
    public profile_photo: string;
    public cover_photo: string;
    public phone_number: string;
    public provider: string;
    public additional_info: string;
    public bio: string;
    public show_tour: boolean;

    constructor(data: UserProfileData = {}) {
        this.id = data.id || '';
        this.username = data.username || '';
        this.email = data.email || '';
        this.first_name = data.first_name || '';
        this.last_name = data.last_name || '';
        this.preferred_name = data.preferred_name || '';
        this.profile_photo = data.profile_photo || '';
        this.cover_photo = data.cover_photo || '';
        this.phone_number = data.phone_number || '';
        this.provider = data.provider || '';
        this.additional_info = data.additional_info || '';
        this.bio = data.bio || '';
        this.show_tour = data.show_tour || false;
    }

    static async fetchByIdAuth(user_id: string, accessToken: string): Promise<UserProfile | null> {
        try {
            const resp = await api.profile(accessToken, user_id, false);
            if (resp.data) {
                return new UserProfile(resp.data);
            } else {
                console.error('Profile not found for the given user_id:', user_id);
                return null;
            }
        } catch (error) {
            console.error('Error fetching profile by user_id:', error);
            return null;
        }
    }

    static async getPublicProfile(user_id: string): Promise<UserProfile | null> {
        return await api.getPublicProfile(user_id);
    }

    static async getProfilesByIds2(user_ids: string[], ttlSeconds = 3600): Promise<UserProfile[]> {
        // Function to fetch data from the server
        const fetchProfiles = async (ids: string[]): Promise<UserProfile[]> => {
            const resp = await api.getPublicProfiles(ids);
            if (resp.data) {
                return resp.data.profiles.map((profileData: UserProfileData) => new UserProfile(profileData));
            } else {
                console.error('No profiles found for the given user_ids:', ids);
                return [];
            }
        };

        // Separate profiles to fetch from cache vs. server
        const cachedProfiles: UserProfile[] = [];
        const uncachedUserIds: string[] = [];

        // Check cache using the helper function
        for (const user_id of user_ids) {

            try {
                const profile = await cacheWithTTL(
                    [`user-profile-${user_id}`], // Cache key
                    async () => fetchProfiles([user_id]), // Fetch function
                    ttlSeconds // Cache TTL
                );

                // Check if a valid profile was found
                if (profile) {
                    cachedProfiles.push(profile[0]); // The result is an array from fetchProfiles
                }
            } catch {
                // Add to uncached list if not found in cache
                uncachedUserIds.push(user_id);
            }
        }

        // Fetch uncached profiles from server
        if (uncachedUserIds.length > 0) {
            const newProfiles = await fetchProfiles(uncachedUserIds);
            return [...cachedProfiles, ...newProfiles];
        }

        return cachedProfiles;
    }

}
