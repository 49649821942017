// store/authStore.ts
import { defineStore } from 'pinia';
import { UserProfile } from '@/models/UserProfile';
import { AuthTokens } from '@/interfaces/';
import api from '@/services/api';
import route from '@/router';
import dayjs from 'dayjs';
import { EventEmitter } from 'events';

const authStoreEvents = new EventEmitter();

interface AuthStoreState {
    authTokens: AuthTokens;
    userProfile: UserProfile;
    tokenSetTime: number | null;
}

const refreshSubscribers: ((value: string | null) => void)[] = [];
let isRefreshing = false;


export const useAuthStore = defineStore('authTokens', {
    state: (): AuthStoreState => ({
        authTokens: {
            AccessToken: '',
            AccessTokenExpiresIn: 0,
            RefreshToken: '',
            RefreshTokenExpiresIn: 0,
            TokenType: '',
        },
        userProfile: new UserProfile(),
        tokenSetTime: null,
    }),
    persist: {
        storage: localStorage,
    },

    actions: {
        initAuth() {
            // Load the persisted tokens from local storage
            this.setAuthTokens(this.authTokens);
        },
        ensureAuthTokens() {
            return this.authTokens.AccessToken && this.authTokens.AccessToken.trim();
        },
        setAuthTokens(tokens: AuthTokens) {
            this.authTokens = tokens;
            this.tokenSetTime = Date.now();
            isRefreshing = false;
            console.log("Tokens set at", new Date());
        },
        getAuthTokens() {
            return this.ensureAuthTokens() ? this.authTokens : null;
        },
        getAccessToken() {
            return this.authTokens.AccessToken || '';
        },
        async refreshTokens() {
            if (isRefreshing) {
                console.log("Returning existing refresh promise.");
                return isRefreshing;
            }
            try {
                console.log('Refreshing Tokens from authStore');
                const refreshToken = this.authTokens.RefreshToken || null;

                if (!refreshToken) {
                    console.log('No refresh token available.');
                    return null;
                }

                if (isRefreshing) {
                    return new Promise((resolve) => {
                        console.log("Pushing resolve", resolve)
                        refreshSubscribers.push(resolve); // Add the resolve function to the queue
                    });
                }

                isRefreshing = true; // Mark the refresh as in progress

                const duration = 60; // 60 minutes.  Change this to 1 or 2 minutes for testing
                const refreshResponse = await api.refreshToken(refreshToken, duration);
                console.log('Refresh Response:', refreshResponse);
                if (refreshResponse.status !== 200 || !refreshResponse.data.Auth_Tokens) {
                    console.error('Token refresh failed with response:', refreshResponse);
                    // clear auth and redirect to login
                    this.clearAuth();
                    await route.push({ name: 'login' });

                    // Notify queued requests that the refresh failed
                    isRefreshing = false;
                    refreshSubscribers.forEach((callback) => callback(null));
                    refreshSubscribers.length = 0;

                    return null;
                }

                const newAuthTokens = {
                    ...this.authTokens,
                    ...refreshResponse.data.Auth_Tokens,
                };

                this.setAuthTokens(newAuthTokens);  // Save the new tokens to the store
                this.showTokenState()
                // emit tokenRefreshed event which is listened by WebSocketStore to update the accessToken
                authStoreEvents.emit('tokenRefreshed', newAuthTokens.AccessToken);
                refreshSubscribers.forEach((callback) => callback(newAuthTokens.AccessToken));
                refreshSubscribers.length = 0; // Clear the queue

                isRefreshing = false; // Mark the refresh as complete
                return newAuthTokens;
            } catch (error) {
                console.error('Failed to refresh token', error);
                this.clearAuth();

                // Notify queued requests that the refresh failed
                isRefreshing = false;
                refreshSubscribers.forEach((callback) => callback(null));
                refreshSubscribers.length = 0;
                return null;
            }
        },

        clearAuth() {
            console.trace("YOU ARE CLEARING THE AUTH")
            this.authTokens = {
                AccessToken: '',
                AccessTokenExpiresIn: 0,
                RefreshToken: '',
                RefreshTokenExpiresIn: 0,
                TokenType: '',
            };

            this.userProfile = new UserProfile();
            this.tokenSetTime = null;
            isRefreshing = false;

        },
        showTokenState() {
            console.log({
                "AccessToken Expired": this.isAccessTokenExpired(),
                "Refresh Token Expired": this.isRefreshTokenExpired(),
                "AccessToken Expires in": this.getAccessTokenExpiryTime(),
                "Refresh Token Expires in": this.getRefreshTokenExpiryTime(),
            });
        },

        isTokenExpiringSoon(bufferInSeconds = 60): boolean {
            if (!this.authTokens.AccessToken || !this.tokenSetTime) return true;

            const currentTime = Date.now();
            const expiryTime = this.tokenSetTime + (this.authTokens.AccessTokenExpiresIn ?? 0) * 1000;
            const bufferTime = bufferInSeconds * 1000;

            return currentTime + bufferTime >= expiryTime;
        },

        isAccessTokenExpired(): boolean {
            if (!this.authTokens.AccessToken || !this.tokenSetTime) return true;
            const currentTime = Date.now();
            const expiryTime = this.tokenSetTime + (this.authTokens.AccessTokenExpiresIn ?? 0) * 1000;
            return currentTime > expiryTime;
        },

        isRefreshTokenExpired(): boolean {
            if (!this.authTokens.RefreshToken || !this.tokenSetTime) return true;
            const currentTime = Date.now();
            const expiryTime = this.tokenSetTime + (this.authTokens.RefreshTokenExpiresIn ?? 0) * 1000;
            return currentTime > expiryTime;
        },
        getAccessTokenExpiryTime(): string {
            if (!this.tokenSetTime || !this.authTokens.AccessTokenExpiresIn) return "Expired";

            const expiryTime = dayjs(this.tokenSetTime).add(this.authTokens.AccessTokenExpiresIn, 'second');
            const now = dayjs();
            const secondsRemaining = expiryTime.diff(now, 'second');

            // Format based on remaining time
            if (secondsRemaining <= 0) {
                return "Expired";
            } else if (secondsRemaining < 60) {
                return `${secondsRemaining} seconds`;
            } else if (secondsRemaining < 3600) {
                return `${Math.floor(secondsRemaining / 60)} minutes`;
            } else {
                return `${Math.floor(secondsRemaining / 3600)} hours`;
            }
        },

        getRefreshTokenExpiryTime(): string {
            if (!this.tokenSetTime || !this.authTokens.RefreshTokenExpiresIn) return "Expired";

            const expiryTime = dayjs(this.tokenSetTime).add(this.authTokens.RefreshTokenExpiresIn, 'second');
            const now = dayjs();
            const secondsRemaining = expiryTime.diff(now, 'second');

            // Format based on remaining time
            if (secondsRemaining <= 0) {
                return "Expired";
            } else if (secondsRemaining < 60) {
                return `${secondsRemaining} seconds`;
            } else if (secondsRemaining < 3600) {
                return `${Math.floor(secondsRemaining / 60)} minutes`;
            } else if (secondsRemaining < 86400) {
                return `${Math.floor(secondsRemaining / 3600)} hours`;
            } else {
                return `${Math.floor(secondsRemaining / 86400)} days`;
            }
        },


        // UserProfile related methods
        setProfile(profile: UserProfile) {
            this.userProfile = profile;
        },
        getUserProfile() {
            if (!this.userProfile) {
                console.error("No user profile available");
                window.location.href = "/login";
                return new UserProfile();
            }
            return this.userProfile;
        },
    },
});

export { authStoreEvents };
