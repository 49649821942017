<template>
  <div v-if="challenge" class="footer">
    <v-btn class="nav-button" @click="likeChallenge">
      <v-icon :color="isLiked ? '#FF5C39' : 'default'" size="24" class="mr-2">mdi-thumb-up-outline</v-icon>
        {{ challengeCount }}
    </v-btn>

    <v-btn  class="nav-button" @click="$router.push({ path: '/challenge-details', query: { challenge_id: challenge.challenge_id, challenge_user_id: challenge.user_id } })">
      <v-icon size="24" class="mr-2">mdi-account-multiple-outline</v-icon>
      {{ challenge.response_count }}
    </v-btn>

    <v-btn  class="nav-button" @click="$router.push({ path: '/challenge-details', query: { challenge_id: challenge.challenge_id, challenge_user_id: challenge.user_id } })">
      <v-icon size="24" class="mr-2">mdi-chart-bar-stacked</v-icon>
      {{ challenge.view_count }}
    </v-btn>

    <v-btn class="nav-button" @click="$router.push({ path: '/challenge-details', query: { challenge_id: challenge.challenge_id, challenge_user_id: challenge.user_id } })">
      <v-icon size="24" class="mr-2">mdi-comment-text-outline</v-icon>
      {{ challenge.comment_count }}
    </v-btn>

  </div>
</template>


<script setup lang="ts">
import { useRouter } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import api from '@/services/api';
import { useAuthStore } from '@/store/authStore';
import { useLikeStore } from '@/store/likeStore';
import { useChallengeStore } from '@/store/challengeStore';
import { Challenge } from '@/models/Challenge';

const authStore = useAuthStore();
const likeStore = useLikeStore();
const challengeStore = useChallengeStore();
const accessToken = authStore.getAccessToken() as string;

const props = defineProps({
  challenge: {
    type: Object, 
    required: true,
  },
  challenge_id: {
    type: String,
    required: false,
  },
});

const challenge = computed(() => {
    return challengeStore.getChallenge(props.challenge.challenge_id);
});


console.log("ScrollItemFooter")

const isLiked = computed(() => {
    if (!props.challenge) return false;
    return likeStore.isLiked(`CHALLENGE#${props.challenge.challenge_id}`)
});

const challengeCount = computed(() => {
  if (!props.challenge) return 0;
    const challenge = challengeStore.getChallenge(props.challenge.PK);
    return challenge?.like_count || 0;
});

async function likeChallenge() {
  if (props.challenge) {
    const storedChallenge = challengeStore.getChallenge(props.challenge.PK);
    if (!storedChallenge) return;

    const currentLikeCount = Number(storedChallenge.like_count ?? 0);

    if (likeStore.isLiked(`CHALLENGE#${props.challenge.challenge_id}`)) {
      likeStore.removeLike(`CHALLENGE#${props.challenge.challenge_id}`);
      challengeStore.updateChallenge(props.challenge.PK, { like_count: currentLikeCount - 1 });
      await api.unlike(accessToken, "CHALLENGE", props.challenge.challenge_id)
    }
    else {
      likeStore.addUserLikes([`CHALLENGE#${props.challenge.challenge_id}`]);
      challengeStore.updateChallenge(props.challenge.PK, { like_count: currentLikeCount + 1 });
      await api.like(accessToken, "CHALLENGE", props.challenge.challenge_id)
    }
  }
}
// add onMounted method on next line.
// Initial load
onMounted(async () => {
    if (props.challenge_id && !props.challenge) {
      const response = await api.getChallenge(props.challenge_id);
      const apiChallenge: Challenge = response.data; 
      challengeStore.addChallenges([apiChallenge]);
    }
});



</script>

<style scoped>
.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 10px;
    padding-bottom: 20px;
    border-bottom: 2px solid rgb(50, 50, 50);
}

.nav-button {
    font-size: 16px;
}

.nav-button .v-icon {
  color: inherit; /* Use the inherited color or set a default color */
}

.nav-button:hover .v-icon:not(.liked) {
  color: #FF5C39; /* Change color on hover for icons that are not "liked" */
}

.nav-button .v-icon.liked {
  color: #FF5C39; /* Keep the "liked" icon color */
}

</style>