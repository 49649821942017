<template>
  <v-app>
    <TopNavBar />
    <v-main>
      <v-btn
        v-if="installPromptVisible"
        color="secondary"
        block
        class="mt-4"
        @click="installApp"
        style="border-radius: 8px; padding: 16px; margin-bottom: 15px;"
      >
        Install App
      </v-btn>
      <router-view />
    </v-main>
    <BottomNavBar />
  </v-app>
</template>

<script setup>
import TopNavBar from './components/TopNavBar.vue';
import BottomNavBar from './components/BottomNavBar.vue';
import { ref } from 'vue';
import { useUserProfileStore } from '@/store/userProfileStore';
import { useAuthStore } from '@/store/authStore';
import { useWebSocketStore } from '@/store/webSocketStore';

// Setup the stores
const store = useUserProfileStore();
const authStore = useAuthStore();
const webSocketStore = useWebSocketStore();

// Expose the store globally for testing in the browser console
window.userProfileStore = store;

const installPromptVisible = ref(false);

// Handle the install button click
const installApp = () => {
    console.log('Install button clicked');

    // CHeck if the deferredPrompt is available
    if (!window.installPrompt) {
        console.log('No deferredPrompt found');
        return;
    }

    window.installPrompt.prompt();
    window.installPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
      } else {
          console.log('User dismissed the install prompt');
      }
          
      window.installPrompt = null;
      installPromptVisible.value = false;
    });
};

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  window.installPrompt = e;
  installPromptVisible.value = true;
});
</script>


<style lang="scss">
/* Import global styles here */
@import '@/assets/scss/global.scss';
</style>
