<template>
    <v-app-bar  v-if="viewNavBar()" app hide-on-scroll>
      <v-toolbar class="topnav" color="duelly_black">
        <vbtn>
        <v-icon
          v-if="showBackArrow"
          size="32"
          @click="goBack"
        >
          mdi-arrow-left
        </v-icon>
      </vbtn>
        <v-img
          src="@/assets/duelly_top_nav_log.svg"
          alt=""
          max-height="50"
          max-width="150"
          class="ml-3"
          @click="$router.push('/feed')"
        ></v-img>

        <v-spacer></v-spacer>

        <v-btn
          id="step5"
          @click="$router.push('/feedback')"
          class="beta-btn"
          color="secondary"
          base-color="blue"
          text
          elevation="2"
        >
          Beta <v-icon>mdi-arrow-down-box</v-icon>
      </v-btn>
      
      <v-spacer></v-spacer>

      <v-badge
          v-if="unreadNotifications > 0"
          class="custom-badge"
          color="#FF5C45"
          :content="unreadNotifications"
          overlap
          offset-x="32"
          offset-y="6"
        >
          <v-icon
            id="step4"
            color=""
            size=32
            class="mr-8"
            @click="$router.push('/notifications')"
          >mdi-bell</v-icon>
        </v-badge>
        <v-icon
          v-else
          id="step4"
          size="32"
          color=""
          class="mr-8"
          @click="$router.push('/notifications')"
        >
          mdi-bell
        </v-icon>
      </v-toolbar>
    </v-app-bar>
</template>
  
<script setup>
import { computed } from 'vue';
import { useNoticeStore } from '@/store/noticeStore';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const route = useRoute();

const noticeStore = useNoticeStore();
const unreadNotifications = computed(() => noticeStore.unreadCount);
const showBackArrow = computed(() => route.path !== '/feed');

const viewNavBar = () => {
    return route.path !== '/login'  && route.path !== '/';
};

console.log("window.history.length:", window.history.length);


const goBack = () => {
  if (window.history.length > 2) {
    router.back();
  } else {
    // No meaningful history; redirect to home
    router.push('/feed');
  }
};
</script>
  
<style scoped>
.topnav {
  border-bottom: 2px solid rgb(50, 50, 50);
}

.beta-btn {
  background-color: var(--v-theme-secondary);
  /* color: blue; */
  border: 1px solid #00C1DE !important;
  padding: 0 8px;
  min-width: auto;
  font-weight: normal;
  box-shadow: none;
  text-transform: none;
  border: 1px solid var(--v-theme-secondary);
  size: 32px;
}

/* .custom-badge2 {
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
} */
</style>
  