import { notificationServiceClient } from '@/services/api';
import { Challenge } from '@/models/Challenge';
import api from '@/services/api';
import axios from 'axios';
import { AxiosResponse } from 'axios';
import { FetchNotificationsApiResponse } from '@/interfaces';


class NotificationService {
    static async send_user_push(saveObj: Record<string, any>): Promise<AxiosResponse> {
        try {
            const response = await notificationServiceClient.post('/notification/send-user-push', saveObj);
            console.log('Feedback successfully sent:', response.data);
            return response;
        } catch (error) {
            console.error('Error sending feedback:', error);
            throw error;
        }
    }

    static async getChallenge(challenge_id: string): Promise<Challenge> {
        const resp = await notificationServiceClient.get('/challenge/get-challenge', {
            params: {
                challenge_id,
            },
        });
        return resp.data.challenge;
    }

    static async getNotifications(
        limit: number,
        lastEvaluatedKey: any = null
    ): Promise<AxiosResponse<FetchNotificationsApiResponse>> {
        const serializedKey = lastEvaluatedKey ? JSON.stringify(lastEvaluatedKey) : null;
        return notificationServiceClient.get('/notification/get', {
            params: {
                "limit": limit,
                "lastEvaluatedKey": serializedKey,
            }
        });
    }

    static async pushSubscribe(subscription: any) {
        return notificationServiceClient.post('/notification/push-subscription', subscription);
    }

    static async getUnreadNotificationCount() {
        return notificationServiceClient.get('/notification/get-unread-count');
    }

    static async markAsRead(notifications: any[]) {
        return notificationServiceClient.post('/notification/mark-as-read',
            { notifications: notifications }
        );
    }

    static async deleteNotifications(access_token: string, notifications: any[]) {
        // Ensure notifications contain only PK and SK
        const notificationsPayload = notifications.map(notification => ({
            PK: notification.PK,
            SK: notification.SK,
        }));
        return notificationServiceClient.post('/notification/delete-notifications',
            { notifications: notificationsPayload },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            }
        );
    }
}

export default NotificationService;
