/**
 * WebSocketService is a class that manages WebSocket connections for a user.
 * It establishes a connection to a WebSocket server, handles message reception, 
 * and periodically reconnects to ensure the connection remains active.
 * 
 * Features:
 * - Automatically reconnects the WebSocket every X minutes (configurable).
 * - Clears reconnection intervals on closure to prevent unnecessary reconnect attempts.
 * - Can send messages through the WebSocket when it's open.
 * 
 * Usage:
 * - Call `connect()` to establish the WebSocket connection for a user.
 * - `startReconnection()` periodically closes and re-establishes the connection to 
 *   ensure it remains active and synced.
 * - `sendMessage(data)` allows sending a message through the WebSocket if the 
 *   connection is open.
 * - `close()` will close the WebSocket connection and stop any reconnection logic.
 * 
 * Parameters:
 * - `userId`: The ID of the user initiating the WebSocket connection.
 * - `environment`: The environment (e.g., 'dev', 'prod') where the WebSocket is deployed.
 * 
 * This service is optimized for scaling and can be used in applications where 
 * maintaining an active WebSocket connection for notifications is critical.
 */
import { EventEmitter } from 'events';
import { WebSocket as NodeWebSocket } from 'ws';

const WebSocketClass = typeof WebSocket !== 'undefined' ? WebSocket : NodeWebSocket;
type WebSocketClassType = WebSocket | NodeWebSocket; // Union type for both WebSocket implementations

const getWebSocketBaseUrl = () => {
    const WS_BASE_URL = process.env.VUE_APP_WS_BASE_URL;
    if (!WS_BASE_URL) {
        throw new Error('WebSocket base URL is not defined.');
    }
    return WS_BASE_URL;
};

export interface WebSocketServiceOptions {
    accessToken: string;
    environment: string;
    heartbeatInterval: number;
    deviceId: string;
}

export default class WebSocketService extends EventEmitter {
    public static instance: WebSocketService | null = null;
    private accessToken: string;
    private env: string;
    private static socket: InstanceType<typeof WebSocketClass> | null = null;
    public static activeConnection = false;
    private heartbeatInterval: number;
    private heartbeatIntervalId: any;
    private deviceId: string;
    private pingCount: number;
    private pongCount: number;

    constructor(props: WebSocketServiceOptions) {
        super(); // Initialize the EventEmitter

        this.accessToken = props.accessToken;
        this.env = props.environment || 'dev';
        WebSocketService.socket = null;
        this.heartbeatInterval = props.heartbeatInterval; // Ping every 10 seconds for testing purposes
        this.heartbeatIntervalId = null;
        this.deviceId = props.deviceId;
        this.pingCount = 0;
        this.pongCount = 0;

        WebSocketService.instance = this;
    }

    static isActive() {
        return WebSocketService.activeConnection;
    }
    public getSocketStatus(): WebSocketClassType | null {
        return WebSocketService.socket;
    }

    static getInstance(options?: WebSocketServiceOptions): WebSocketService | null {
        console.trace("DebugSingleton: WebSocketService.getInstance called")
        if (!WebSocketService.instance) {
            if (!options) {
                return null
            }
            // console.log("DebugSingleton: Create new instance")
            WebSocketService.instance = new WebSocketService(options);
        }
        // console.log("DebugSingleton: Returning singleton instance")
        return WebSocketService.instance;
    }

    connect() {
        if (WebSocketService.isActive()) {
            console.log('❌ WebSocket connection already established');
            return { "status": "error", "message": "WebSocket connection already established" };
        }
        WebSocketService.activeConnection = true
        WebSocketService.socket = null;
        const WS_BASE_URL = getWebSocketBaseUrl();

        WebSocketService.socket = new WebSocketClass(`${WS_BASE_URL}?token=${this.accessToken}&device_id=${this.deviceId}`);

        WebSocketService.socket.onopen = () => {
            console.log('🚀 WebSocketService: connection established');
            this.emit('connected');
            this.startHeartbeatCheck();
        };

        WebSocketService.socket.onclose = (event: any) => {
            WebSocketService.activeConnection = false
            console.log('WebSocketService: connection closed:', event.reason, 'Code:', event.code);
            this.emit('disconnected', event);
        };

        WebSocketService.socket.onerror = (error: any) => {
            console.trace('WebSocket error:', error);
            this.emit('websocketError', error);
        };

        WebSocketService.socket.onmessage = (message: any) => {
            try {
                const parsedData = JSON.parse(message.data);
                if (parsedData.message === 'pong') {
                    this.pongCount++;
                    this.emit('pong');
                } else {
                    console.log('New message received:', parsedData);
                    this.emit('messageReceived', parsedData);
                }
            } catch (err) {
                console.error('Failed to parse message:', err);
            }
        };

        return { "status": "success" }
    }

    // Start the heartbeat ping/pong mechanism to check if the server is responsive
    startHeartbeatCheck() {
        if (this.heartbeatIntervalId) {
            clearInterval(this.heartbeatIntervalId); // Clear any previous interval
        }
        console.log("restarting heartbeat with interval", this.heartbeatInterval)
        this.heartbeatIntervalId = setInterval(() => {
            // console.log("PING", this.accessToken)
            console.log(`PINGS=${this.pingCount}, PONGS=${this.pongCount}`);

            if (this.pingCount > this.pongCount) {
                console.log("Missed pongs. Closing WebSocket...", this.pingCount, this.pongCount);
                this.pingCount = 0;
                this.pongCount = 0;
                this.reconnect();
            } else {
                this.sendMessage({
                    'message': 'ping',
                });
                this.pingCount++;
            }

        }, this.heartbeatInterval);
    }

    // Clear the heartbeat interval when not needed
    clearHeartbeatCheck() {
        if (this.heartbeatIntervalId) {
            clearInterval(this.heartbeatIntervalId);
            this.heartbeatIntervalId = null;
            console.log('Stopped heartbeat checks.');
        }
    }

    // Perform WebSocket reconnection using an exponential backoff strategy
    reconnect() {
        WebSocketService.activeConnection = false;
        this.clearHeartbeatCheck(); // Ensure heartbeat check is cleared before reconnecting
        const delay = 1000
        setTimeout(() => {
            console.log(`Reconnecting WebSocket after ${delay}ms...`);
            this.connect();
        }, delay);
    }


    // Send a message through the WebSocket if it’s open
    sendMessage(data: object) {
        if (WebSocketService.socket && WebSocketService.socket.readyState === WebSocketClass.OPEN) {
            const payload = {
                ...data,
                access_token: this.accessToken,
                device_id: this.deviceId,
            };
            // console.log('Sending message:', payload);
            WebSocketService.socket.send(JSON.stringify(payload));
        } else {
            console.error('WebSocket is not open. Cannot send message.');
        }
    }

    updateAccessToken(newAccessToken: string) {
        this.accessToken = newAccessToken;
        console.log("Access token updated in WebSocketService");
        if (!WebSocketService.isActive()) {
            console.log("WS not active, reconnecting");
            this.connect();
        }
    }

    // Close the WebSocket connection and clear intervals
    close() {
        WebSocketService.activeConnection = false;
        console.log("WS: Closing WebSocket connection");
        if (WebSocketService.socket) {
            WebSocketService.socket.close(1000);
        }
        this.clearHeartbeatCheck(); // Stop heartbeat checks when closing
        WebSocketService.instance = null; // Clear the instance
        WebSocketService.socket = null; // Clear the socket
        console.log("WebSocketService.socket is now", WebSocketService.socket);
    }
}
