/* eslint-disable no-console */

import { register } from 'register-service-worker'
import api from '@/services/api';
import NotificationService from './services/NotificationService';
// log all env vars here
console.log("Registering service worker with the following environment variables:")
console.log(process.env)

// VAPID public key from your backend
const VAPID_PUBLIC_KEY = process.env.VUE_APP_VAPID_PUBLIC_KEY as string
console.log("VAPID_PUBLIC_KEY", VAPID_PUBLIC_KEY);
if (!VAPID_PUBLIC_KEY) {
  console.error('VAPID_PUBLIC_KEY is not set. Push notifications will not work.');
}




// Convert VAPID key to the format needed by the subscription options
function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

async function subscribeToPushNotifications(registration: ServiceWorkerRegistration) {
  try {
    // Check for existing subscription
    const existingSubscription = await registration.pushManager.getSubscription();
    console.log("Existing Subscription:", existingSubscription);

    // Extract keys from existing subscription (if any)
    let subscription = existingSubscription;
    if (existingSubscription) {
      console.log("Already subscribed to push notifications");

      const p256dhKey = existingSubscription.getKey("p256dh");
      const authKey = existingSubscription.getKey("auth");

      const p256dh = p256dhKey
        ? btoa(String.fromCharCode(...new Uint8Array(p256dhKey)))
        : null;
      const auth = authKey
        ? btoa(String.fromCharCode(...new Uint8Array(authKey)))
        : null;

      console.log("p256dh:", p256dh);
      console.log("auth:", auth);
    } else {
      // Create a new subscription if none exists
      console.log("VAPID_PUBLIC_KEY", VAPID_PUBLIC_KEY);
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(VAPID_PUBLIC_KEY),
      });
      console.log("New subscription created:", subscription);
    }

    // Always send subscription to backend (existing or new)
    const response = await NotificationService.pushSubscribe(subscription);
    console.log("Response from backend:", response);

    if (!response) {
      console.error("Failed to register subscription with the backend:", response);
      throw new Error("Subscription registration failed.");
    }

    console.log("Subscription successfully sent to backend");
    return subscription;
  } catch (error) {
    console.error("Error subscribing to push notifications:", error);
    if (Notification.permission === "denied") {
      console.warn("Permission for notifications was denied");
    }
  }
}

function showUpdateNotification() {
  // Create a banner element
  const banner = document.createElement('div');
  banner.id = 'update-banner';
  banner.style.position = 'fixed';
  banner.style.bottom = '0';
  banner.style.left = '0';
  banner.style.width = '100%';
  banner.style.backgroundColor = '#fff3cd';
  banner.style.color = '#856404';

  // banner.style.backgroundColor = '#FF5C39'; // duelly primary color
  // banner.style.color = '#00C1DE'; // duelly secondary color
  banner.style.padding = '15px';
  banner.style.textAlign = 'center';
  banner.style.boxShadow = '0 -2px 5px rgba(0,0,0,0.3)';
  banner.style.zIndex = '9999';

  // Set the banner's inner HTML
  banner.innerHTML = `
    <span>New version available.</span>
    <button id="refresh-button" style="margin-left: 15px; padding: 5px 10px;">Update</button>
  `;

  // Append the banner to the body
  document.body.appendChild(banner);

  // Add click event listener to the refresh button
  const refreshButton = document.getElementById('refresh-button');
  if (refreshButton) {
    refreshButton.addEventListener('click', () => {
      window.location.reload();
    });
  }
}

async function requestNotificationPermission(): Promise<boolean> {
  if (!('Notification' in window)) {
    console.warn('This browser does not support notifications')
    return false
  }

  console.log("Notification permission:", Notification.permission);


  if (Notification.permission === 'granted') {
    console.log("Notification permission already granted."); // Skipping prompt
    return true
  }

  if (Notification.permission === 'denied') {
    console.warn("Permission for notifications was denied.");
    return false
  }

  const permission = await Notification.requestPermission()
  return permission === 'granted'
}




if (process.env.NODE_ENV === 'production') {
  register('/service-worker.js', {
    async ready(registration) {
      console.log("Service worker is ready.");

      const permissionGranted = await requestNotificationPermission()
      if (permissionGranted) {
        await subscribeToPushNotifications(registration)
      }

      // Add custom push notification handling
      navigator.serviceWorker.ready.then((registration) => {
        if (registration.active) {
          console.log("Attaching push/message/notificationCLick event listener to the active service worker.");
        }
      });
    },
    registered(registration) {
      setInterval(() => {
        registration.update();
      }, 30 * 1000);
    },
    cached() {
      // console.log('Content has been cached for offline use.')
    },
    updatefound(registration) {
      const newWorker = registration.installing;
      if (newWorker) {
        newWorker.addEventListener('statechange', () => {
          if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
            window.location.reload();
          }
        });
      }
    },
    updated() {
      // showUpdateNotification();
    },
    offline() {
      // console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

export const pushNotifications = {
  requestPermission: requestNotificationPermission,
  subscribe: subscribeToPushNotifications
}
