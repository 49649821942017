<script setup>
import { ref, watch } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

const props = defineProps({
  fetchItems: {
    type: Function,
    required: true,
  },
  resetKey: {
    type: Number,
    required: true,
  },
});

const items = ref([]);
const loading = ref(false);
const lastEvaluatedKey = ref(null);
const lastItemRef = ref(null);

// Set up the observer for the last item to trigger more fetches
const setLastItemRef = (index) => {
  return (el) => {
    if (index === items.value.length - 1) {
      lastItemRef.value = el;
    }
  };
};

// exposed methods
const addItem = (newItem) => {
  items.value.unshift(newItem); // Add the new comment at the top
};
defineExpose({ addItem });


// Fetch items with the provided fetch function
const fetchItems = async () => {
  if (loading.value) return;

  loading.value = true;

  try {
    const response = await props.fetchItems(lastEvaluatedKey.value);
    items.value.push(...response.items);
    lastEvaluatedKey.value = response.lastEvaluatedKey;
  } catch (error) {
    console.error('Error fetching items:', error);
  } finally {
    loading.value = false;
  }
};

watch(
  () => props.resetKey,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      items.value = [];
      lastEvaluatedKey.value = null;
      fetchItems(); // Fetch new items based on the new topic
    }
  }
);

// Watch for intersection of the last item and load more items when visible
watch(lastItemRef, (newLastItem) => {
  if (newLastItem) {
    useIntersectionObserver(lastItemRef, ([{ isIntersecting }]) => {
      if (isIntersecting && !loading.value && lastEvaluatedKey.value) {
        fetchItems();
      }
    });
  }
});

// Fetch initial items on mount
fetchItems();
</script>

<template>
  <div class="view-wrapper">
    <!-- Iterate through items and emit a click event when an item is clicked -->
    <div
      v-for="(item, index) in items"
      :key="item.id"
      class="view-content"
      :ref="setLastItemRef(index)"
      @click="$emit('item-clicked', item)"
    >
      <!-- Slot allows the parent to define how to render each item -->
      <slot :item="item"></slot>
    </div>

    <!-- Display a Vuetify spinner when fetching more items -->
    <div v-if="loading" class="d-flex justify-center mt-4">
      <v-progress-circular indeterminate color="primary" />
    </div>
  </div>
</template>
