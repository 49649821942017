// services/axiosInterceptor.ts
import { AxiosInstance, InternalAxiosRequestConfig, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useAuthStore } from '@/store/authStore';
import { useWebSocketStore } from '@/store/webSocketStore';
import route from '@/router';
import { EventEmitter } from 'events';

// Extend Axios's InternalAxiosRequestConfig to include _retry
declare module 'axios' {
    export interface InternalAxiosRequestConfig {
        _retry?: boolean;
    }
}

// Initialize EventEmitter instance
const authEventEmitter = new EventEmitter();

// Function to set up interceptors
export const setupInterceptors = (client: AxiosInstance) => {
    // Request interceptor
    client.interceptors.request.use(
        async (config: InternalAxiosRequestConfig) => {
            const authStore = useAuthStore();
            console.log("Access Tokens expire in", authStore.getAccessTokenExpiryTime())
            if (authStore.isTokenExpiringSoon()) {
                console.log("Refreshing tokens as they are about to expire");
                await authStore.refreshTokens();
            }
            const accessToken = authStore.getAccessToken();
            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
                client.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            }

            return config;
        },
        (error: AxiosError) => {
            return Promise.reject(error);
        }
    );
    client.interceptors.response.use(
        async (response: AxiosResponse) => {
            return response
        },
        async (error: AxiosError) => {
            const authStore = useAuthStore();

            const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };

            if (!originalRequest) {
                return Promise.reject(error);
            }

            // These routes require a user to be authenticated before accessing/interacting
            const restrictedRoutes = ['/challenge/add-comment', '/challenge/like'];

            // Handle 401 Unauthorized and 403 Forbidden errors
            if ((error.response?.status === 401 || error.response?.status === 403) && !originalRequest._retry) {
                console.info('Auth session invalid. Redirecting to login...', originalRequest.url);
                if (restrictedRoutes.some((route) => originalRequest.url?.includes(route))) {
                    alert('You must be logged in to perform this action');
                    authStore.clearAuth();
                    await route.push({ name: 'login' });
                    return Promise.resolve(null);
                }
                return Promise.reject(error);
            }

            const propogate_404s = ['/user/login'];

            // Handle specific status codes
            if (error.response?.status === 404) {
                const shouldPropagate = propogate_404s.some((endpoint) => originalRequest?.url?.includes(endpoint));

                if (shouldPropagate) {
                    console.error(`Ignoring 404 for ${originalRequest.url}`);
                    return Promise.reject(error);
                }
                console.error(`Resource not found: 404 on ${originalRequest.url}`);
                return Promise.resolve(null); // prevent propagation of error
            }

            if (error.response?.status === 500) {
                console.error(`Server error: 500 on ${originalRequest.url}`);
                return Promise.resolve(null);
            }

            return Promise.reject(error);
        }
    );
};

// Export the EventEmitter for other services to listen to
export { authEventEmitter };
