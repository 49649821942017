// models/Challenge.ts

export enum ChallengeStatus {
    PENDING = "pending",  // Challenge has been created
    PROCESSING = "processing",  // Video is uploading
    VIDEO_PROCESSED = "video_processed",  // Video is uploaded and processed
    PUBLISHED = "published",  // User has published the video
    ACTIVE = "active",  // Challenge is active.  Accepting Responses
    JUDGING = "judging", // Challenge is in judging phase.  No longer accepting responses
    COMPLETED = "completed",  // Challenge is completed

    SCORING = "scoring",  // Challenge is being scored... is this used?
    FAILED = "failed",  // not currently used
}

export interface Challenge {
    PK: string;
    SK: string;
    user_id: string;
    title: string;
    video: string;
    thumbnail: string;
    status?: ChallengeStatus;
    type_user_id?: string;
    created_at?: string;
    updated_at?: string;
    challenge_id: string;
    description?: string;
    thumbnail_alternates?: string[];
    // Shouldn't these all be numbers?
    start_date?: string;
    end_date?: string;
    judge_end?: string;
    category?: string;
    trending_score?: number;
    like_count?: number;
    comment_count?: number;
    response_count?: number;
    has_prize?: boolean;
    prize?: string;
    prize_photo?: string;
    link_to_prize_details?: string;
    video_of_prize?: string;
    prize_video_thumbnail?: string;
    prize_video_thumbnail_alternates?: string[];
    prize_video_status?: string;

    judges_prize?: string;
    judges_prize_photo?: string;
    link_to_judges_prize_details?: string;
    judges_prize_video?: string;
    judges_prize_video_thumbnail?: string;
    judges_prize_video_thumbnail_alternates?: string[];
    judges_prize_video_status?: string;

    requirements?: string;
    terms?: string;
}
