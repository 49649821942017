<template>
  <div class="view-content center">
    <TopicSlider class="duelly-regular mt-5" @topic-selected="fetchChallengesByTopic"/>
  </div>

  <div class="view-wrapper mt-1">

    <content-loader v-if="loading" viewBox="0 0 250 1100" primaryColor="#1A1A1A" secondaryColor="#2E2E2E">
      <circle cx="20" cy="20" r="10" />
      <rect x="35" y="10" rx="5" ry="15" width="84%" height="20" />
      <rect x="5" y="35" rx="5" ry="5" width="96%" height="300" />
      <circle cx="20" cy="355" r="10" />
      <rect x="35" y="345" rx="5" ry="15" width="84%" height="20" />
      <rect x="5" y="370" rx="5" ry="5" width="96%" height="300" />
    </content-loader>

    <InfiniteScrollList 
      :fetchItems="fetchChallenges" 
      :resetKey="topic"
    >
      <template #default="{ item }">
        <div class="scroll-item">
          <ScrollItemHeader 
            :challenge="item"
            :route_path="{ path: '/challenge-details', query: { challenge_id: item.challenge_id, challenge_user_id: item.user_id } }"
          />
          <VideoPlayer2 
            :title="item.title" 
            :videoSrc="item.video"  
            :previewImage="item.thumbnail"
            @play="submitView(item.challenge_id)"
            class="video-player"
          />
          <ScrollItemFooter :challenge="item"/>
        </div>
      </template>
    </InfiniteScrollList>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted} from 'vue';
import TopicSlider from '../components/TopicSlider.vue';
import VideoPlayer2 from '@/components/VideoPlayer2.vue';
import ScrollItemHeader from '@/components/ScrollItemHeader.vue';
import ScrollItemFooter from '@/components/ScrollItemFooter.vue';
import InfiniteScrollList from '@/components/InfiniteScrollList.vue';
import { useChallengeStore } from '@/store/challengeStore';
import api from '@/services/api';
import { ContentLoader } from 'vue-content-loader'
import { useNoticeStore } from '@/store/noticeStore';
import { useLikeStore } from '@/store/likeStore';
import { useAuthStore } from '@/store/authStore';
import { useWebSocketStore } from '@/store/webSocketStore';
import { useUserProfileStore } from '@/store/userProfileStore';
import ChallengeService from '@/services/ChallengeService';
import NotificationService from '@/services/NotificationService';
import { tourDriver } from '@/tour';

import "driver.js/dist/driver.css";

// Setup Stores
const challengeStore = useChallengeStore();
const noticeStore = useNoticeStore();
const likeStore = useLikeStore();
const authStore = useAuthStore();
const webSocketStore = useWebSocketStore();
const userStore = useUserProfileStore();

const topic = ref("Trending");
const loading = ref(true);

const fetchChallenges = async (lastEvaluatedKey: any) => {
  console.log("lastEvaluatedKey", lastEvaluatedKey)
  console.log("topic.value", topic.value)

  loading.value = true;

  let response;
  if (topic.value === "Trending") {
    response = await ChallengeService.getTrendingChallenges(50, lastEvaluatedKey);
  } else if (topic.value === "Recents") {
    response = await ChallengeService.getRecentChallenges(50, lastEvaluatedKey);
  } else if (topic.value === "Upcoming") {
    response = await ChallengeService.getUpcomingChallenges(50, lastEvaluatedKey);
  } else {
    response = {
      challenges: [],
      last_evaluated_key: null,
      userProfiles: [],
      user_likes: [],
    };
    // response = await ChallengeService.getChallengesByTopic(topic.value, 10, lastEvaluatedKey);
  }

  loading.value = false;

  // Process response
  if (response.user_likes) {
    likeStore.addUserLikes(response.user_likes);
  }

  if (response.challenges) {
    console.log("response.challenges", response.challenges);
    challengeStore.addChallenges(response.challenges);
  }

  if (response.userProfiles) {
    console.log("userProfiles", response.userProfiles);
      // itrate over the userProfiles and add them to and them id: profile to the userStore.profiles array
    response.userProfiles.forEach((profile) => {
      userStore.profiles[profile.id] = profile;
    });
  }

  const targetIds = response.challenges.map((challenge: any) => challenge.challenge_id);
  const targetType = "CHALLENGE";
  const subType = "TEMPORARY";

  if (targetIds.length > 0) {
    console.log("targetIds", targetIds);
    const subresponse = await ChallengeService.subscribe(targetIds, targetType, subType);
    console.log("subresponse", subresponse);
  }

  return {
    items: response.challenges,
    lastEvaluatedKey: response.last_evaluated_key,
  };
};

const submitView = async (challenge_id: string) => {
    await ChallengeService.addView(challenge_id);
}

// Fetch challenges when the topic changes
const fetchChallengesByTopic = (newTopic: string) => {
  topic.value = newTopic;
  return newTopic;
};


onMounted(async () => {
  const accessToken = authStore.getAccessToken();
  if (accessToken) {
      webSocketStore.initialize(accessToken);
  }
  // Fetch users current unread count
  NotificationService.getUnreadNotificationCount().then((response) => {
    noticeStore.unreadCount = response.data.count
  });

  NotificationService.getNotifications(1000, null).then((response) => {
    noticeStore.notifications = response.data.notifications;
  });

  const userProfile = authStore.getUserProfile();
  console.log("userProfile", userProfile);
  if (userProfile.show_tour === true) {
    setTimeout(() => {
      tourDriver.drive()
    }, 1000);
  }
});
</script>

<style scoped>
.video-player {
  width: 100%;
  object-fit: contain;
  max-width: 700px;         
  height: auto; 
  border-radius: 8px;
  background-color: black;
}
</style>
