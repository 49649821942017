<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import QRCodeComponent from '@/components/QRCodeComponent.vue'
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/authStore';


const envName = process.env.VUE_APP_ENV_NAME || 'dev'
const url = envName === 'prd'
  ? 'https://duelly.io/login'
  : `https://${envName}-pwa.duelly.net/login`

// We'll store the QR code width in a reactive variable
const qrcodeWidth = ref(350)
const videowidth = ref("700px")
const largescreen = ref(true)
const screenWidth = ref(window.innerWidth)
const screenHeight = ref(window.innerHeight)

const router = useRouter();
const authStore = useAuthStore();

const videos = [
  {
    src: 'https://dev-duelly-global-cloudfront.duelly.net/news/video/1694295089348.mp4',
    poster: 'https://dev-duelly-global-cloudfront.duelly.net/news/image/1694295067670.jpg',
  },
  {
    src: 'https://dev-duelly-global-cloudfront.duelly.net/news/video/1723994936831.mp4',
    poster: 'https://dev-duelly-global-cloudfront.duelly.net/news/image/1723994876530.jpg',
  },
  {
    src: 'https://dev-duelly-global-cloudfront.duelly.net/news/video/1694298622442.mp4',
    poster: 'https://dev-duelly-global-cloudfront.duelly.net/news/image/1694298618281.jpg',
  },
  {
    src: 'https://dev-duelly-global-cloudfront.duelly.net/news/video/1692478698103.mp4',
    poster: 'https://dev-duelly-global-cloudfront.duelly.net/news/image/1692478795932.jpg',
  },
  {
    src: 'https://dev-duelly-global-cloudfront.duelly.net/news/video/1686111770484.mp4',
    poster: 'https://dev-duelly-global-cloudfront.duelly.net/news/image/1686111763651.jpg',
  },
  
];

const mediaQuery = window.matchMedia('(max-width: 1000px)')
const updateDimensions = () => {
  screenWidth.value = window.innerWidth;
  screenHeight.value = window.innerHeight;
};

const updateQrCodeSize = (e) => {
    console.log("e.matches", e.matches)
    largescreen.value = e.matches ? false : true
    videowidth.value = e.matches ? "100%" : "700px"
    console.log("largescreen", largescreen.value)
}
onMounted(() => {
    if (!authStore.isAccessTokenExpired()) {
        router.push('/feed');
    }
    
    // Add event listener to update dimensions on resize
    window.addEventListener('resize', updateDimensions);
    mediaQuery.addEventListener('change', updateQrCodeSize)
    // Set initial size
    updateQrCodeSize(mediaQuery)

})
onUnmounted(() => {
    // Clean up the event listener
    mediaQuery.removeEventListener('change', updateQrCodeSize)
    window.removeEventListener('resize', updateDimensions);
})
</script>

<template>
  <!-- <p style="position: absolute">{{ screenWidth }} x {{ screenHeight }}</p> -->

  <div class="container">
    <div class="content-wrapper">
      <div v-if="largescreen" class="qrcode-section">
        <QRCodeComponent :url="url" :width="qrcodeWidth" />
        <v-btn color="primary" :width="qrcodeWidth" @click="router.push('/login');">
          Sign-Up or Login
        </v-btn>
      </div>
      <div v-else>
        <v-btn color="primary" @click="router.push('/login');">
          Sign-Up or Login
        </v-btn>
      </div>

      <div class="video-section">
        <v-carousel 
          class="video-carousel" 
          :hide-delimiter-background="true"
          :hide-delimiters="true"
          :show-arrows="false"
          :cycle="false"
x        >
          <v-carousel-item v-for="(video, index) in videos" :key="index">
            <video
              :src="video.src"
              :poster="video.poster"
              controls
              preload="metadata"
              :width="videowidth"
              playsinline
              webkit-playsinline
              x5-playsinline
            ></video>
          </v-carousel-item>
        </v-carousel>
      </div>

      <div v-if="largescreen && screenWidth > 1600" class="qrcode-section">
        <QRCodeComponent :url="url" :width="qrcodeWidth" />
        <v-btn color="primary" :width="qrcodeWidth" @click="router.push('/login');">
          Sign-Up or Login
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style>
.container {
  background-image: url('@/assets/imgs/duelly_zoom_background.webp') !important;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin-top: 100px;
}

.qrcode-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 auto;
}

.video-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-carousel {
  width: 100%;
}

@media (max-width: 1000px) {
  .content-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .qrcode-section {
    margin-top: 400px;
  }

  .video-carousel {
    width: 100vw;
  }
}
@media (min-height: 601px) and (max-height: 1000px) {
  .content-wrapper {
    margin-top: 200px;
  }
}
</style>
