import { defineStore } from 'pinia';
import { Notification, LastEvaluatedKey } from '@/interfaces';
import NotificationService from '@/services/NotificationService';
import { useAuthStore } from './authStore';

interface NoticeStoreState {
    unreadCount: number;
    notifications: Notification[];
    lastEvaluatedKey: LastEvaluatedKey | null;
}


export const useNoticeStore = defineStore('noticeStore', {
    state: (): NoticeStoreState => ({
        unreadCount: 0,
        notifications: [],
        lastEvaluatedKey: null,
    }),
    actions: {
        async fetchNotifications(
            lastEvaluatedKey: LastEvaluatedKey | null = null,
            limit = 25
        ): Promise<{ items: Notification[]; lastEvaluatedKey: LastEvaluatedKey | null } | void> {
            const accessToken = useAuthStore().getAccessToken();
            if (!accessToken) {
                console.error('No access token available.');
                return;
            }
            const response = await NotificationService.getNotifications(limit, lastEvaluatedKey);
            return {
                items: response.data.notifications,
                lastEvaluatedKey: response.data.lastEvaluatedKey,
            };
        },
        getUnread(): Notification[] {
            return this.notifications.filter((item) => item.SK.startsWith('#unread'));
        },
        getRead(): Notification[] {
            return this.notifications.filter((item) => item.SK.startsWith('#read'));
        },
    },
});
