import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import FeedView from '../views/FeedView.vue'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import { useAuthStore } from '@/store/authStore'
import { useWebSocketStore } from '@/store/webSocketStore'
import WebSocketService from '@/services/WebSocketService'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/feed',
    name: 'feed',
    component: FeedView,
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/confirm-login',
    name: 'confirm-login',
    component: () => import(/* webpackChunkName: "profile" */ '../views/ConfirmLoginView.vue'),
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/user-management',
    name: 'user-management',
    component: () => import(/* webpackChunkName: "user-management" */ '../views/UserManagement.vue'),
    meta: { requiresAuth: true }  // Private route
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue'),
    meta: { requiresAuth: true }  // Private route
  },
  {
    path: '/public-profile',
    name: 'public-profile',
    component: () => import(/* webpackChunkName: "PublicProfileView" */ '../views/PublicProfileView.vue'),
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/user-challenges',
    name: 'user-challenges',
    component: () => import(/* webpackChunkName: "UserChallenges" */ '../views/UserChallenges.vue'),
    meta: { requiresAuth: true }  // Private route
  },
  {
    path: '/user-judged-challenges',
    name: 'user-judged-challenges',
    component: () => import(/* webpackChunkName: "UserJudgedChallenges" */ '../views/UserJudgedChallenges.vue'),
    meta: { requiresAuth: true }  // Private route
  },
  {
    path: '/user-responses',
    name: 'user-responses',
    component: () => import(/* webpackChunkName: "UserResponses" */ '../views/UserResponses.vue'),
    meta: { requiresAuth: true },  // Private route
  },
  {
    path: '/response-view',
    name: 'response-view',
    component: () => import(/* webpackChunkName: "ResponseView" */ '../views/ResponseView.vue'),
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/response-carousel',
    name: 'response-carousel',
    component: () => import(/* webpackChunkName: "ResponseCarousel" */ '../views/ResponseCarousel.vue'),
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/challenge-details',
    name: 'challenge-details',
    component: () => import(/* webpackChunkName: "ChallengeDetails" */ '../views/ChallengeDetailsView.vue'),
    meta: { requiresAuth: false },  // Public route
  },
  {
    path: '/challenge-responses',
    name: 'challenge-responses',
    component: () => import(/* webpackChunkName: "ChallengeResponses" */ '../views/ChallengeResponses.vue'),
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/challenge-leaderboard',
    name: 'challenge-leaderboard',
    component: () => import(/* webpackChunkName: "ChallengeLeaderBoard" */ '../views/ChallengeLeaderBoard.vue'),
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/challenge-final-board',
    name: 'challenge-final-board',
    component: () => import(/* webpackChunkName: "ChallengeFinalBoard" */ '../views/ChallengeFinalBoard.vue'),
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/challenge-judge-final-board',
    name: 'challenge-judge-final-board',
    component: () => import(/* webpackChunkName: "ChallengeJudgeFinalBoard" */ '../views/ChallengeJudgeFinalBoard.vue'),
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/user-challenge',
    name: 'user-challenge',
    component: () => import(/* webpackChunkName: "UserChallenge" */ '../views/UserChallenge.vue'),
    meta: { requiresAuth: true }  // Private route
  },
  {
    path: '/user-response',
    name: 'user-response',
    component: () => import(/* webpackChunkName: "UserResponse" */ '../views/UserResponse.vue'),
    meta: { requiresAuth: true },  // Private route
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "NotificationsView" */ '../views/NotificationsView.vue'),
    meta: { requiresAuth: true }  // Private route
  },
  {
    path: '/create-challenge',
    name: 'create-challenge',
    component: () => import(/* webpackChunkName: "create" */ '../views/CreateChallengeView.vue'),
    meta: { requiresAuth: true }  // Private route
  },
  {
    path: '/record-video',
    name: 'record-video',
    component: () => import(/* webpackChunkName: "record-video" */ '../components/LiveRecordingComponent.vue'),
    meta: { requiresAuth: true }  // Private route
  },
  {
    path: '/upload-video',
    name: 'upload-video',
    component: () => import(/* webpackChunkName: "UploadComponent" */ '../components/UploadComponent.vue'),
    meta: { requiresAuth: true }  // Private route
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "TestView" */ '../views/TestView.vue'),
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/prize-details',
    name: 'prize-details',
    component: () => import(/* webpackChunkName: "ChallengePrizeView" */ '../views/ChallengePrizeView.vue'),
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/qr-code',
    name: 'qr-code',
    component: () => import(/* webpackChunkName: "InviteFriendsView" */ '../views/InviteFriendsView.vue'),
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import(/* webpackChunkName: "InviteFriendsView" */ '../views/FeedbackView.vue'),
    meta: { requiresAuth: false }  // Public route
  },
  {
    path: '/send-user-push',
    name: 'send-user-push',
    component: () => import(/* webpackChunkName: "SendUserPush" */ '../views/SendUserPush.vue'),
    meta: { requiresAuth: false }  // Public route
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  console.log("beforeEach", to, from);
  const authStore = useAuthStore();
  const wsStore = useWebSocketStore();

  let accessToken = authStore.getAccessToken();
  if (authStore.authTokens.RefreshToken && authStore.isTokenExpiringSoon()) {
    console.log("Refreshing tokens from beforeEach");
    await authStore.refreshTokens();
    accessToken = authStore.getAccessToken();
  }

  // Allow public routes to be accessed without authentication
  if (to.meta.requiresAuth === false) {
    next(); // Proceed to the route as it's public
  }

  // For protected routes, ensure tokens are valid
  else if (to.meta.requiresAuth) {
    if (accessToken) {
      if (!WebSocketService.isActive()) {
        console.log('❌ Websocket not active - initializing');
        wsStore.close()
        wsStore.initialize(accessToken);
      } else {
        console.log("✅ Websocket already active");
      }
      next(); // Continue to route if tokens are valid
    } else {
      console.error('No valid access token found. Clearing Auth');
      // If no valid tokens, clear auth and redirect to login
      authStore.clearAuth();
      wsStore.close();
      next({ name: 'login' });
    }
  }
});

export default router
