import { defineStore } from 'pinia';
import WebSocketService, { WebSocketServiceOptions } from '@/services/WebSocketService';
import { useNoticeStore } from './noticeStore';
import { useAuthStore, authStoreEvents } from '@/store/authStore';
import { useChallengeStore } from './challengeStore';
import { useResponseStore } from './responseStore';
import ChallengeService from '@/services/ChallengeService';
import { Challenge } from '@/models/Challenge';
import { Response } from '@/models/Response';



export const useWebSocketStore = defineStore('webSocketStore', {
    state: () => ({
        connected: false,
        deviceId: "",
        visibilityChangeListenerAdded: false,
        wsService: null as WebSocketService | null,
        listenersRegistered: false
    }),

    actions: {
        initialize(accessToken: string) {
            if (WebSocketService.isActive()) {
                console.log('❌ WebSocketService is already initialized.');
                return this.wsService
            }

            if (!this.deviceId) {
                this.deviceId = this.generateDeviceId();
            }

            const options: WebSocketServiceOptions = {
                accessToken,
                environment: 'dev',
                heartbeatInterval: 10000,
                deviceId: this.deviceId,
            };

            this.wsService = WebSocketService.getInstance(options);
            if (!this.wsService) {
                console.error('WebSocketService initialization failed.');
                return;
            }

            // Listen for token refresh events and update WebSocketService
            authStoreEvents.on('tokenRefreshed', (newAccessToken) => {
                if (!this.wsService) {
                    console.error('WebSocketService not initialized.');
                    return;
                }
                this.wsService.updateAccessToken(newAccessToken);
                console.log("WebSocketService accessToken updated on refresh.");
            });

            if (!this.visibilityChangeListenerAdded && this.wsService) {
                this.visibilityChangeListenerAdded = true;

                // Stop ws connections when user navigates away
                // and restart when user comes back
                document.addEventListener('visibilitychange', async () => {
                    const authStore = useAuthStore();
                    if (!this.wsService) {
                        console.error('WebSocketService not initialized.');
                        return;
                    }
                    if (document.hidden) {
                        console.log("Page hidden - closing WebSocket connection.");
                        this.wsService.close();
                    } else {
                        console.log("Page Visible - renablling WebSocket connection.");
                        let accessToken = authStore.getAccessToken()
                        if (!accessToken) {
                            console.info('No access token available.');
                            return;
                        }
                        // Check that authtokens are still valid
                        authStore.showTokenState();
                        if (authStore.isAccessTokenExpired()) {
                            console.log("Token expired - refreshing tokens.");
                            await authStore.refreshTokens();
                            accessToken = authStore.getAccessToken()
                        }

                        if (accessToken) {
                            this.wsService.updateAccessToken(accessToken);
                            this.wsService.connect();
                            this.setupWebSocketListeners(this.wsService as WebSocketService);
                        }
                    }
                });
            }
            // Set up WebSocket event listeners initially
            this.setupWebSocketListeners(this.wsService as WebSocketService);
            this.wsService.connect();
            return this.wsService;
        },
        setupWebSocketListeners(wsService: WebSocketService) {
            if (this.listenersRegistered) {
                console.log("❌ WebSocket listeners already registered.");
                return;
            }
            wsService.on('messageReceived', this.onMessageReceived);
            this.listenersRegistered = true; // Set the flag to true
            console.log("✅ WebSocket listeners registered.");
        },
        async onMessageReceived(data: any) {
            console.log('Store: Received message:', data);
            if (typeof data.unread_count === 'number') {
                const noticeStore = useNoticeStore();
                noticeStore.unreadCount = data.unread_count;
            } else if (data.message.event_type === 'ws_notice') {
                if (data.message.message === 'challenge_modified') {
                    const challenge: Challenge = data.message.challenge;
                    const challengeStore = useChallengeStore();
                    challengeStore.addChallenges([challenge]);
                    console.log('🚀 Saved challenge to store', challenge.challenge_id);
                } else if (data.message.message === 'response_modified') {
                    const response: Response = data.message.response;
                    const responseStore = useResponseStore();
                    responseStore.add([response]);
                    console.log('🚀 Saved new Response to Store', response.PK);
                } else {
                    console.log("Unhandled message type:", data);
                }
            }
        },
        generateDeviceId(): string {
            return (crypto as any).randomUUID();
        },
        close(): void {
            if (this.wsService) {
                this.wsService.close();
                this.wsService = null;
            }
        }
    },
});
