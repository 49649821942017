import { driver } from "driver.js";
import { useAuthStore } from "./store/authStore";
import api from '@/services/api';


export const tourDriver = driver({
    showProgress: true,
    steps: [
        {
            element: '#stepStart',
            popover: {
                title: 'Welcome to Duelly',
                description: 'The worlds first social media platform dedicated to competitions!',
                side: "left",
                align: 'start',
            }
        },
        {
            element: '#step1',
            popover: {
                title: 'Home',
                description: 'This is the Home Button.  Clicking this will return you here',
                side: 'left',
                align: 'start',
            }
        },
        {
            element: '#step2',
            popover: {
                title: 'Video Upload',
                description: 'Upload Video to start a new competition or to reply to an existing one.',
                side: "top",
                align: 'center',
            }
        },
        {
            element: '#step3',
            popover: {
                title: 'User Management',
                description: 'Here you can adjust your preferences.',
                side: "right",
                align: 'start',
            }
        },
        {
            element: '#step4',
            popover: {
                title: 'Notification Center',
                description: 'Check your notifications here.',
                side: "right",
                align: 'start',
            }
        },
        {
            element: '#step5',
            popover: {
                title: 'Feedback',
                description: 'Leave feedback on the beta version here.',
                side: "bottom",
                align: 'center',
            }
        },
    ],
    onPopoverRender: (popover, { config, state }) => {
        // Dynamically adjust the popover's width when rendered
        const { wrapper } = popover;

        // Adjust the arrow position
        const arrow = wrapper.querySelector('.driver-popover-arrow');
        if (arrow instanceof HTMLElement) {
            const side = state.activeStep?.popover?.side;

            if (side === 'top') {
                // For top position, arrow should point downward
                arrow.style.left = '50%';
                arrow.style.transform = 'translateX(-50%) ';
                arrow.style.width = '10px';
                arrow.style.height = '10px';
            } else if (side === 'bottom') {
                // For bottom position, arrow should point upward
                arrow.style.left = '50%';
                arrow.style.transform = 'translateX(-50%)';
                arrow.style.width = '10px';
                arrow.style.height = '10px';
            } else if (side === 'left' || side === 'right') {
                // Reset styles for left/right to preserve default appearance
                arrow.style.left = '';
                arrow.style.transform = '';
                arrow.style.clipPath = ''; // Reset shape
            }
        }
    },

    onHighlightStarted: (element) => {
        setTimeout(() => {
            const popover = document.querySelector('.driver-popover');
            if (popover instanceof HTMLElement) {
                popover.style.width = '100px'; // Set the width for the popover
            }
        }, 0);
    },

    onDestroyStarted: async () => {
        const authStore = useAuthStore();
        if (tourDriver.hasNextStep()) {
            confirm("Are you sure?")
            tourDriver.destroy();
        } else {
            const userProfile = authStore.getUserProfile();
            userProfile.show_tour = false;
            authStore.setProfile(userProfile);
            tourDriver.destroy();
            const resp = await api.setProfileDirect(userProfile.id, userProfile);
        }
    }
});

